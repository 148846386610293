import React, {useRef} from 'react'
import {useFrame} from '@react-three/fiber'

function Crystal() {
    const mesh = useRef()

    useFrame((state, delta) => (mesh.current.rotation.y += delta))

    return (
        <mesh
            ref={mesh} 
            rotation={[45,0,0]}>
            <dodecahedronGeometry args={[1.5]} /> 
            <meshStandardMaterial color={'blue'} />
        </mesh>
    )
}

export default Crystal