import React from 'react'
import {useThree} from '@react-three/fiber'
import {Text} from '@react-three/drei'

function AdaptiveTitle(props) {
    const viewport = useThree(state => state.viewport);
    return (
        <Text fontSize={viewport.height > viewport.width ? 0.5 : 1.0} color='orange' anchorX='center' anchorY='middle' position={[0,0,1.6]}>
         {props.text}
        </Text>
    )
}

export default AdaptiveTitle