import './App.css';
import { Canvas } from '@react-three/fiber'
import Crystal from './components/Crystal'
import AdaptiveTitle from './components/AdaptiveTitle';

function App() {
  return (
    <div className="canvasContainer">
      <Canvas className='canvas1'>
        <color attach='background' args={['black']} />
        <ambientLight color={'0x040404'} />
        <directionalLight position={[-2,1,1]} color={'cyan'} intensity={5.5} />
        <Crystal />
        <AdaptiveTitle text={'DanVR.Dev'} />
      </Canvas>
    </div>
  );
}

export default App;
